//  alert('dsi sql');
// console.log('DSi_sql.js');


    import axios from "axios";
    import { registra_log } from './DSi_funcoes'
    var url = sessionStorage['url']
    // var url = localStorage['url']

    // console.log('DSi_sql.js - url:'+url);



    //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
    async function salvar_tabela(that,tabela, obj_edita, dialog_name){

        // console.log('obj_edita:', obj_edita);

        if (dialog_name){
            that[dialog_name] = false
        }


        //GLOBAL
        if (obj_edita.CODIGO >0 ){
            // that.tabela_update(tabela, that.item_edita)
            await that.tabela_update(tabela, obj_edita)
        }else{
            await that.tabela_create(tabela, obj_edita)
        }
    };

    async function editar_tabela(that, item, obj_edita_name, dialog_name) {
        // that.items_clicou = item //ver se vou usar isso aqui
        //  console.log('editar_tabela:', item);
        //  console.log('obj_edita_name:', obj_edita_name);
         
         
         that[obj_edita_name] = Object.assign({}, item)
         that[dialog_name] = true
    };

    function nova_tabela(that, obj_edita_name,dialog_name){

        // for (var member in obj_edita)  obj_edita[member]= '..'; 
        that[obj_edita_name] = {}

        if (dialog_name){
            that[dialog_name] = true
        }
    };

    async function  remover_tabela(that, tabela, obj_edita, titulo){
        //GLOBAL
        // console.log(obj_edita);
        let ide = obj_edita.CODIGO
        if (ide > 0){
            if (confirm("Deseja realmente Excluir "+titulo+' - id: '+ide+' ?')){

                let sql

                if (['CONVENIO','DOUTORES'].includes(tabela)) {
                    sql = `update ${tabela}  set ATIVO = 'X' where codigo = ${ide}`
                }
                else{
                    sql = 'delete from '+tabela+' where codigo ='+ide
                }
                
                
                // console.log(sql);
                // return false
                await that.crud_sql(sql)
                await that.tabela(tabela,'delete')

                await registra_log('EXCLUSAO',`EXCLUIU codigo ${ide} da tabela ${tabela}`)
            }
        }
    };


    //EXECUTAR UM SQL TIPO UPDATE
    async function crud_sql (that, sql) {

        const res = await axios.post(url+'conexao.php', {
            acao:   6,
            meuSQL: sql
        })
    
        // console.log(sql);
        // console.log(res.data);
        that.alerta(res.data, "success");//so retorna ok ou error

        return true
    };


    //ABRE OS DADOS DA TABELA
    async function crud_abrir_tabela(that, sql, items_name){

        // console.log('DSi_sql: crud_abrir_tabela - url:'+url);
        //GLOBAL    
        // console.log('c1:',items_name);

        try{
          that[items_name] = []//limpa antes
        }catch(e){}

        var meuSQL = sql
        // console.log(meuSQL);
                
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
        })

        try{
          that[items_name] = await res2.data 
        }catch(e){}


        // se nao for informado o items_name entao ele ira retornar os dados
        if (items_name){
            return true
        }
        else{
            return  res2.data
        }
        

    };


    export async function tabela_update(that, tabela, obj_edita){
        //GLOBAL

        // console.log('dsi_sql: tabela_update:', obj_edita);
        // console.log(obj_edita);

        let id = obj_edita.CODIGO
        if (id<1){
        that.alerta('Código inválido!','red')
        }
        else{

            const data = {
                    acao: 3,
                    id:           id,
                    id_field:    'CODIGO', 
                    table:       tabela,
                    fields:      obj_edita,
            }  

            const res = await axios.post(url+'conexao.php', data);
            that.alerta(res.data, "success");
            that.tabela(tabela,'update')//close open

            // console.log('tabela_update:', res.data);
        }
    };

    export async function tabela_create(that, tabela, obj_edita){

        // console.log('dsi_sql: tabela_create:', obj_edita);

        const data = {
                acao: 2,
                table:             tabela,
                fields:            obj_edita,
        }  

        const res = await axios.post(url+'conexao.php', data);

        try {
            that.alerta(res.data, "success");
          }
        catch(err) {}



        try {
            that.tabela(tabela,'create')//close open
          }
        catch(err) {
             
        }

        // console.log('tabela_create:', res.data);

    };


    export function teste(){
    // export function teste(){
        console.log('eu sou o teste');
    };


    export async function crud_empresa(that){
        
        let sql = 
            `SELECT
                CONSULTORIOS.NOME,
                CONSULTORIOS.CEP,
                CONSULTORIOS.ENDERECO,
                CONSULTORIOS.CIDADE,
                CONSULTORIOS.UF,
                CONSULTORIOS.FONE1,
                CONSULTORIOS.FONE2,
                CONSULTORIOS.CELULAR,
                CONSULTORIOS.CNPJ,
                CONSULTORIOS.EMAIL1,
                CONSULTORIOS.MSG_WHATS,
                CONSULTORIOS.MSG_WHATS_2,
                CONFIGURACOES.IDE_PROGRAMA
            FROM
            CONSULTORIOS,
            CONFIGURACOES`

        let r = await crud_abrir_tabela(that, sql, '')


        await definir(r)

        async function definir(dados){
          
          dados = dados[0]
          sessionStorage['emp_ide']     = dados.IDE_PROGRAMA
          sessionStorage['emp_nome']     = dados.NOME
        //   sessionStorage['emp_razao']    = dados.RAZAO_SOCIAL
          sessionStorage['emp_fone1']    = dados.FONE1
          sessionStorage['emp_fone2']    = dados.FONE2
          sessionStorage['emp_celular']  = dados.CELULAR
          sessionStorage['emp_cnpj']     = dados.CNPJ
          sessionStorage['emp_cep']      = dados.CEP
          sessionStorage['emp_endereco'] = dados.ENDERECO +', CEP:'+ dados.CEP + ', '+dados.CIDADE+'-'+dados.UF
          sessionStorage['emp_cidade']   = dados.CIDADE
          sessionStorage['emp_uf']       = dados.UF
          sessionStorage['emp_email']    = dados.EMAIL1
        //   sessionStorage['emp_logo']     = dados.PATH_LOGO
        //   sessionStorage['emp_multa']    = dados.MULTA.replaceAll(',','.')
        //   sessionStorage['emp_juros']    = dados.JUROS.replaceAll(',','.')


          //logo   
          sessionStorage['emp_url_logo'] = sessionStorage['url']+'images/'+ dados.PATH_LOGO

         //msg do whats
         sessionStorage['emp_whats_obs']   =  dados.MSG_WHATS
         sessionStorage['emp_whats_aviso'] = dados.MSG_WHATS_2
        

          //configuracoes
        //   sessionStorage['conf_mostrar_juros'] = dados.C_FIN_VL_SIMPLES
          
        }

        // console.log(r);
        return await r

      };


      export async function enviar_email(para, assunto, texto){
        console.log('enviar_email:');

        const data = {
                  
                  para:      para,
                  assunto:   assunto, 
                  texto:     texto,
        }  

        // console.log(data);
        // const header =   { headers: {'Content-Type': 'multipart/form-data'}}
        const header =   {}

        const res = await axios.post(url+'email.php', data, header);
        console.log(res.data);
        return res.data

    };

    export async function  crud_sql_45(sql){

        // console.log('crud_sql_45:', sql);

        let url = ''
        if (sessionStorage['url'] == '/api/'){
          url = `http://localhost/f/c/clientes/canal.php`
        }
        else{
          url = `https://dsi.app.br/f/c/clientes/canal.php`
        }

        const data = {
                    acao   : '6',
                    meuSQL : sql,
                  } 

        const header = { headers: {'Content-Type': 'multipart/form-data'}}//so funciona com isso daqui
        const res    = await axios.post(url, data, header);
        let retorno  = (res.data);

        //pego o erro que nao conseugi tirar dessa bosta.. to dando um stringreplace nele kkkkkkk dai mudo o retonro para um objejto
        let erro = `<b>Warning</b>:  Missing boundary in multipart/form-data POST data in <b>Unknown</b> on line <b>0</b><br />`
        retorno = retorno.replaceAll(erro,'')
        retorno = retorno.replaceAll(`<br />`,'')
      
        // console.log(retorno);
        return retorno
      };



      export async function  crud_abrir_tabela_canal (sql) {

        let url_45 = ''
        if (sessionStorage['url'] == '/api/'){
            url_45 = `http://localhost/f/c/clientes/canal.php`
        }
        else{
            url_45 = `https://dsi.app.br/f/c/clientes/canal.php`
        }


        const data = {
            acao   : '5',
            meuSQL : sql,
          } 

        // const header = { headers: {'Content-Type': 'multipart/form-data'}}//so funciona com isso daqui
        const header = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
        const res    = await axios.post(url_45, data, header);
        let retorno  = (res.data);

        // console.log('retorno:= ', retorno);

        return retorno
        
     };


    export default {
        salvar_tabela,
        editar_tabela,
        nova_tabela,
        remover_tabela,
        tabela_update,
        tabela_create,
        crud_sql,
        crud_abrir_tabela,
        crud_empresa,
        enviar_email
    };


